import React from 'react'
import {FallbackProps} from 'react-error-boundary'

export const ErrorFallback = ({resetErrorBoundary}: FallbackProps) => {
  return (
    <div className='ErrorBoundary' role='alert'>
      <div className='container'>
        <h1>
          Something <span>went wrong</span>
        </h1>
        <p>We are very sorry for inconvenience.</p>
        <button className='Button' onClick={resetErrorBoundary}>
          Try again
        </button>
      </div>
    </div>
  )
}
