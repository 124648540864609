import React from 'react'
import {ErrorBoundary} from 'react-error-boundary'
import {ErrorFallback} from './ErrorFallback'

import {RecaptchaProvider} from '@lib/recaptcha'

type Props = {
  children: React.ReactNode
}

export const AppProvider = ({children}: Props) => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload()
      }}
    >
      <RecaptchaProvider>{children}</RecaptchaProvider>
    </ErrorBoundary>
  )
}
