import get from 'lodash/get'
import entries from 'lodash/entries'

import nth from 'lodash/nth'
import replace from 'lodash/replace'
import trim from 'lodash/trim'
import Cookies from 'js-cookie'
import * as __axios from 'axios'

import env from '@lib/env'

import en from '../public/locales/en.yml'
import { EMAIL_PATTERN } from './constants'

const locales = {
  en,
}

const currentLocale = Cookies.get('_datawow_2020_locale') || 'en'

const currentLocaleSet = (locale) => {
  Cookies.set('_datawow_2020_locale', locale)
}

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
const t = (key, args = {}) => {
  try {
    const _key = `${currentLocale}.${key}`

    let text = get(locales, _key, _key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {
    console.log(e)
  }
}

const stripTags = (text) => {
  return trim(replace(text, /(<([^>]+)>)/gi, ''))
}

const _axios = __axios.create({
  baseURL: env.API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Api-Key': env.BLOG_API_KEY,
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.

const axios = {
  get: (url, data) => {
    return _axios.get(url, data)
  },

  post: (url, data, options) => {
    return _axios.post(url, data, options)
  },

  patch: (url, data) => {
    return _axios.patch(url, data)
  },

  put: (url, data) => {
    return _axios.put(url, data)
  },

  delete: (url, data) => {
    return _axios.delete(url, {
      data,
    })
  },
}

const typewriter = (t, speed = 50) => {
  const HTML = t.innerHTML

  t.innerHTML = ''

  let cursorPosition = 0,
    tag = '',
    writingTag = false,
    tagOpen = false,
    typeSpeed = speed,
    tempTypeSpeed = 0,
    isDestroy = false,
    timer = null

  const type = () => {
    if (isDestroy) return

    if (writingTag === true) {
      tag += HTML[cursorPosition]
    }

    if (HTML[cursorPosition] === '<') {
      tempTypeSpeed = 0
      if (tagOpen) {
        tagOpen = false
        writingTag = true
      } else {
        tag = ''
        tagOpen = true
        writingTag = true
        tag += HTML[cursorPosition]
      }
    }
    if (!writingTag && tagOpen) {
      tag.innerHTML += HTML[cursorPosition]
    }
    if (!writingTag && !tagOpen) {
      tempTypeSpeed =
        HTML[cursorPosition] === ' ' ? 0 : Math.random() * typeSpeed + 50
      t.innerHTML += HTML[cursorPosition]
    }
    if (writingTag === true && HTML[cursorPosition] === '>') {
      tempTypeSpeed = Math.random() * typeSpeed + 50
      writingTag = false
      if (tagOpen) {
        const newSpan = document.createElement('span')
        t.appendChild(newSpan)
        newSpan.innerHTML = tag
        tag = newSpan.firstChild
      }
    }

    cursorPosition += 1
    if (cursorPosition < HTML.length - 1) {
      timer = window.setTimeout(type, tempTypeSpeed)
    }
  }

  const destroy = () => {
    isDestroy = true
    window.clearTimeout(timer)
  }

  return {
    type,
    destroy,
  }
}

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const validateEmail = (e) => {
  const email = e.target.value
  if (!EMAIL_PATTERN.test(email)) {
    e.target.setCustomValidity(
      'Please enter a valid email address (e.g., user@example.com)'
    )
  } else {
    e.target.setCustomValidity('')
  }
}


export {
  currentLocale,
  currentLocaleSet,
  stripTags,
  t,
  _axios,
  axios,
  typewriter,
  sleep,
  validateEmail,
}
