import React from 'react'
import {debounce} from 'lodash'

import env from '@lib/env'

declare global {
  interface Window {
    grecaptcha: any
  }
}

const RecaptchaContext = React.createContext({
  isRecaptchaValidated: false,
})

function RecaptchaProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [isRecaptchaValidated, isRecaptchaValidatedSet] = React.useState(false)

  React.useEffect(() => {
    const executeRecaptchaValidation = () => {
      window.grecaptcha
        .execute(env.RECAPTCHA_KEY, {action: 'homepage'})
        .then(() => {
          isRecaptchaValidatedSet(true)
        })
    }

    const debounceRecaptchaValidation = debounce(
      executeRecaptchaValidation,
      500
    )

    if (window.grecaptcha) {
      debounceRecaptchaValidation()
    } else {
      const script = document.createElement('script')
      script.src = `https://www.google.com/recaptcha/api.js?render=${env.RECAPTCHA_KEY}`
      script.defer = true
      script.onload = () => {
        window.grecaptcha.ready(() => {
          debounceRecaptchaValidation()
        })
      }
      document.head.appendChild(script)
    }
  }, [])

  const value = {isRecaptchaValidated}

  return (
    <RecaptchaContext.Provider value={value}>
      {children}
    </RecaptchaContext.Provider>
  )
}

function useRecaptcha(): {
  isRecaptchaValidated: boolean
} {
  const context = React.useContext(RecaptchaContext)
  if (context === undefined) {
    throw new Error('useRecaptcha must be used within a RecaptchaProvider')
  }
  return context
}

export {RecaptchaProvider, useRecaptcha}
