import getConfig from 'next/config'

const circleBranch = process.env.CIRCLE_BRANCH

const {serverRuntimeConfig} = getConfig()

const envObj = {
  API_URL: process.env.API_URL || '',
  APP_URL: process.env.APP_URL,
  EMAIL_API_URL: process.env.EMAIL_API_URL,
  BLOG_API_KEY: process.env.BLOG_API_KEY,
  DEMO_API_KEY: process.env.DEMO_API_KEY,
  PDPACORE_API_URL: serverRuntimeConfig.PDPACORE_API_URL,
  PDPACORE_API_KEY: serverRuntimeConfig.PDPACORE_API_KEY,

  RECAPTCHA_KEY: process.env.RECAPTCHA_KEY,
  GTM_CONTAINER_ID: process.env.GTM_CONTAINER_ID,

  CONSENT_WOW_API_URL: process.env.CONSENT_WOW_API_URL,
  CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID:
    process.env.CONSENT_WOW_MARKETING_CONSENT_PURPOSE_ID,
  CONSENT_WOW_CONTACT_API_KEY: process.env.CONSENT_WOW_CONTACT_API_KEY,

  IS_DEV: true,
}

if (circleBranch === 'production') {
  envObj.IS_DEV = false
}

export default {...envObj}
